import { AutoComplete, AutoCompleteProps, Form, Spin } from 'antd';
import { debounce, throttle } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ProviderNominationsApiService from '../../api/ProviderNominationsApiService';
import MemberSearchDTO from '../../models/MemberSearchDTO';
import PnfMemberInfoDTO from '../../models/PnfMemberInfoDTO';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';

interface MemberAutoCompleteProps {
  onSelect: (selected: MemberSearchDTO) => void;
  onSearch: () => void;
  onClear: () => void;
  formItemName: string;
  placeholder?: string;
  clientId?: number;
  isContactInfo?: boolean;
  readOnly?: boolean;
  initialValues?: any;
  allMembers?: PnfMemberInfoDTO[]
  primaryMember?: PnfMemberInfoDTO;
}

const MemberAutoComplete: React.FC<MemberAutoCompleteProps & AutoCompleteProps> = (props) => {
  const [members, setMembers] = useState<MemberSearchDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MemberSearchDTO | undefined>(undefined);
  const authContext = useContext(AuthenticationContext);
  

  // Grabs the form instance component is wrapped in
  const form = Form.useFormInstance();

  const searchApiCall = (searchString: string) => {
    ProviderNominationsApiService.autoCompleteMembers(searchString, props.clientId ?? 0).then((res) => {
      setMembers(res);
      setLoading(false);
    });
  };

  const searchFamilyMemberApiCall = (searchString: string) => {
    ProviderNominationsApiService.autoCompleteFamilyMembers(searchString, props.clientId ?? 0, props.isContactInfo ?? false, props.allMembers ?? [], props.primaryMember?.memberId ?? '').then((res) => {
      setMembers(res);
      setLoading(false);
    });
  };

  const debouncedSearch = authContext.user?.accessRoleId === AccessRole.MEMBER || props.primaryMember?.memberId  ? (useMemo(() => debounce(throttle(searchFamilyMemberApiCall, 500), 500), [props.clientId])) : (useMemo(() => debounce(throttle(searchApiCall, 500), 500), [props.clientId]));

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  useEffect(() => {
    setMembers([]);
  }, [props.clientId]);

  const handleSearch = (searchString: string) => {
    if (selectedMember) {
      setSelectedMember(undefined);
      props.onClear();
    }

    if (!searchString || searchString.length < 3 || !props.clientId) {
      if (members?.length > 0) {
        setMembers([]);
        setLoading(false);
      }
      return;
    }

    setLoading(true);
    props.onSearch();
    debouncedSearch(searchString);
  };

  const handleSelect = (s: any) => {
    // Override the default action of AutoComplete to set the input to the value prop
    // Instead we want to use the displayValue prop
    form.setFieldValue(`${props.formItemName}`, s.displayValue);
    const selected = members.find((f) => f.claimDocId == s.key);
    if (selected) {
      setSelectedMember(selected);
      props.onSelect(selected);
    }
  };

  const handleClear = () => {
    setMembers([]);
    setSelectedMember(undefined);
    props.onClear();
  };

  const transformOptions = (options: any[]) => {
    return options.map((o) => ({
      key: o.claimDocId,
      // value of option must be unique for auto complete for some reason
      // if not unique it will randomly select one of the options with the same value
      // to get around this when we select we will set the value of the field to the "displayValue" prop
      value: `${o.firstName} ${o.lastName} ${moment.utc(o.doB).format('MM-DD-YYYY')}`,
      displayValue: `${o.firstName} ${o.lastName}`,
      label: (
        <>
          {o.firstName} {o.lastName}, {moment.utc(o.doB).format('MM-DD-YYYY')} ({o.relationship})
        </>
      ),
    }));
  };

  const opts = loading
    ? [
        MemberSearchDTO.create({
          id: '',
          name: '',
        }),
      ]
    : members;

  return (
    <AutoComplete
      {...props}
      allowClear
      placeholder={props.placeholder}
      onSearch={handleSearch}
      onSelect={(_, selected) => handleSelect(selected)}
      onClear={handleClear}
      options={transformOptions(opts)}
      dropdownRender={(a) => {
        if (loading) {
          return (
            <div className="center-spinner">
              <Spin spinning={loading} />
            </div>
          );
        }
        return a;
      }}
    />
  );
};

export default MemberAutoComplete;
