// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface MemberSearchDTO { 
    claimDocId: string | null;
    firstName: string | null;
    lastName: string | null;
    doB: moment.Moment | string;
    email: string | null;
    phoneNumber: string | null;
    userId: number | null;
    addressLine1: string | null;
    addressLine2: string | null;
    addressCity: string | null;
    state: string | null;
    zipCode: string | null;
    relationship: string | null;
    familyRelationship: string | null;
    familyJoiner: string | null;
}
const MemberSearchDTO = {
    create: (initValues?: Partial<MemberSearchDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            claimDocId: null,
            firstName: null,
            lastName: null,
            doB: new Date(0).toISOString(),
            email: null,
            phoneNumber: null,
            userId: null,
            addressLine1: null,
            addressLine2: null,
            addressCity: null,
            state: null,
            zipCode: null,
            relationship: null,
            familyRelationship: null,
            familyJoiner: null,
        },
        initValues);
    }
};

export default MemberSearchDTO;