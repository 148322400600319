import { DownOutlined, MenuOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout, Popover, Space } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import React, { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../auth/AuthenticationContext';
import Authorize from '../auth/Authorize';
import HeaderAccountContent from '../components/layout/HeaderAccountContent';
import HeaderContactContent from '../components/layout/HeaderContactContent';
import useUrlQuery from '../hooks/useUrlQuery';
import ForcePasswordChangePage from '../pages/account/ForcePasswordChangePage';
import SideNav from './SideNav';

const { Header, Content, Footer } = Layout;

interface LayoutProps {
  requiredPermissions?: number[] | null;
}

interface LayoutRouteProps {
  mobileview?: string;
}

const AuthorizedLayout = (props: React.PropsWithChildren<LayoutProps>) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [routeQuery] = useUrlQuery<LayoutRouteProps>();
  const authContext = useContext(AuthenticationContext);

  const username = authContext?.user?.firstName
    ? `${authContext?.user?.firstName} ${authContext?.user?.lastName}`
    : authContext?.user?.userName;
  const forcePasswordReset = authContext?.user?.requirePasswordChange ?? false;

  const toggleMenu = () => {
    const htmlElement = document.querySelector('html') as HTMLHtmlElement;
    setMenuCollapsed(!menuCollapsed);
    setLastScrollPosition(htmlElement.scrollTop ?? 0);
  };

  const onSiderBreak = (broken: boolean) => {
    setMenuCollapsed(broken);
    setIsMobile(broken);
    setLastScrollPosition(document.querySelector('html')?.scrollTop ?? 0);
  };

  // If going from from open to closed
  useEffect(() => {
    if (menuCollapsed) {
      const htmlElement = document.querySelector('html') as HTMLHtmlElement;
      htmlElement.scrollTop = lastScrollPosition;
    }
  }, [menuCollapsed]);

  const shouldRenderHeader = () => {
    if (routeQuery?.mobileview) {
      return false;
    }

    return true;
  };

  const getPageContentClassName = () => {
    let className = 'page-content';
    if (!shouldRenderHeader()) {
      className = className += ' mobile-page-content';
    }

    return className;
  };

  return (
    <Authorize requiredPermissions={props.requiredPermissions}>
      <Layout className="layout-fullscreen">
        {shouldRenderHeader() ? (
          <Header>
            <Link to="/" className="header-logo">
              <img src={process.env.PUBLIC_URL + '/claimdoc-logo_simple.svg'} alt="ClaimDOC" />
              <img
                src={process.env.PUBLIC_URL + '/claimdoc-logo_icon.svg'}
                alt="ClaimDOC"
                className="header-logo-icon"
              />
            </Link>
            <Space>
              <Popover
                placement="bottomRight"
                content={HeaderContactContent}
                trigger="click"
                overlayClassName="header-contact-content"
              >
                <Button type="link" style={{ height: 'unset' }}>
                  <PhoneOutlined /> ClaimDOC Assistance <DownOutlined />
                </Button>
              </Popover>
              <Popover
                placement="bottomRight"
                content={HeaderAccountContent}
                trigger="click"
                overlayClassName="header-account-content"
              >
                <Button type="link" style={{ height: 'unset' }}>
                  <UserOutlined /> {username} <DownOutlined />
                </Button>
              </Popover>
              {isMobile ? (
                <Button type="link" onClick={toggleMenu} style={{ height: 'unset' }}>
                  <MenuOutlined className="trigger" />
                </Button>
              ) : null}
            </Space>
          </Header>
        ) : null}
        {forcePasswordReset ? (
          <Layout.Content>
            <Layout>
              <Layout.Content id="app-main" className={getPageContentClassName()}>
                <ForcePasswordChangePage onChange={() => authContext.refresh()} />
              </Layout.Content>
            </Layout>
          </Layout.Content>
        ) : (
          <Layout hasSider={true}>
            <Sider
              trigger={null}
              collapsible={true}
              collapsed={menuCollapsed}
              width={248}
              collapsedWidth={0}
              breakpoint="md"
              onBreakpoint={onSiderBreak}
            >
              <Scrollbars autoHide={true} className="scrollbar-sidenav">
                <SideNav menuCollapsed={menuCollapsed} isMobile={isMobile} />
              </Scrollbars>
            </Sider>
            <Content id="app-main" className={getPageContentClassName()}>
              <div className="full-height">{props.children}</div>
              <Footer style={{ textAlign: 'center', paddingBottom: 0, marginBottom: 0 }}>
                &copy; {new Date().getFullYear()} Claim DOC, LLC
                <br />
                <a href="https://claim-doc.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy and Terms of Use
                </a>
              </Footer>
            </Content>

            {isMobile && !menuCollapsed ? <div onClick={toggleMenu} className="sider-overlay"></div> : null}
          </Layout>
        )}
      </Layout>
    </Authorize>
  );
};

export default AuthorizedLayout;
